<template>
	<div
		class="tn-ui-tile"
		:class="[
			{
				selectable: $props.selectable,
				selected: $props.value,
				vertical: $props.vertical,
				outline: $props.outline,
				chevron: $props.chevron,
				dashed: $props.dashed,
				xs: $props.xs,
				'margin-bottom-xl': $props.marginBottom,
			},
		]"
		@click="!$props.disabled && $emit('update:modelValue', !$props.value)"
	>
		<div
			class="outer-above padding-horizontal-m"
			v-if="$slots['outer-above']"
		>
			<slot name="outer-above"></slot>
		</div>
		<Component
			:is="$props.disabled || $props.noClick || $props.noLink ? 'div' : $props.to ? 'nuxt-link' : 'a'"
			class="inner"
			:to="$props.to || $props.href"
			:class="[
				{
					disabled: $props.disabled,
					loading: $props.loading,
					noClick: $props.noClick,
				},
				`${$props.color && $props.color[0] === '#' ? $props.color : ''}`,
			]"
			:style="$options.innerStyle($props.height, $props.color)"
		>
			<slot name="inner-no-padding-above"></slot>
			<div class="padding-container">
				<div
					class="inner-above"
					v-if="$slots['inner-above']"
				>
					<div
						class="icon"
						:class="{
							'icon-top': $props.iconTop,
						}"
						v-if="$slots['icon']"
					>
						<slot name="icon"></slot>
					</div>
					<slot name="inner-above"></slot>
				</div>
				<div
					class="inner-container"
					v-else
				>
					<div
						class="icon"
						:class="{
							'icon-top': $props.iconTop,
						}"
						v-if="$slots['icon'] && !$slots['inner-above']"
					>
						<slot name="icon"></slot>
					</div>
					<div class="right-of-content">
						<div
							class="header"
							v-if="$slots.header"
						>
							<slot name="header"></slot>
						</div>
						<div
							class="subheader"
							v-if="$slots.subheader"
						>
							<slot name="subheader"></slot>
						</div>
						<div
							class="split-horizontally"
							v-if="$slots.left || $slots.right"
						>
							<div class="left">
								<slot name="left"></slot>
							</div>
							<div class="right">
								<slot name="right"></slot>
							</div>
						</div>
						<div class="body">
							<slot></slot>
						</div>
					</div>
					<CircleTickCheckbox
						:modelValue="$props.modelValue"
						v-if="$props.selectable"
						class="margin-left-auto"
					/>
				</div>
				<div
					class="inner-below"
					v-if="$slots['inner-below']"
				>
					<slot name="inner-below"></slot>
				</div>
			</div>
			<slot name="inner-no-padding-below"></slot>
		</Component>
		<div
			class="outer-below padding-horizontal-m"
			v-if="$slots['outer-below']"
		>
			<slot name="outer-below"></slot>
		</div>
		<div
			class="outer-below outer-no-padding-below"
			v-if="$slots['outer-no-padding-below']"
		>
			<slot name="outer-no-padding-below"></slot>
		</div>
	</div>
</template>

<script>
import * as variables from "@/assets/tokens/json/variables.json";

export default defineNuxtComponent({
	name: "UiTile",

	props: {
		modelValue: { type: Boolean },
		selectable: { type: Boolean },
		disabled: { type: Boolean },
		loading: { type: Boolean },
		vertical: { type: Boolean },
		color: { type: String },
		noClick: { type: Boolean },
		noLink: { type: Boolean },
		outline: { type: Boolean },
		height: {},
		chevron: { type: Boolean },
		dashed: { type: Boolean },
		href: { type: String, default: "javascript:" },
		xs: { type: Boolean },
		marginBottom: { type: Boolean, default: false },
		iconTop: { type: Boolean, default: false },
		to: {},
	},

	innerStyle(height, color) {
		return {
			"min-height": height + "px",
			backgroundColor: this.resolveBackgroundColor(color),
		};
	},

	resolveBackgroundColor(color) {
		if (color && color[0] === "#") {
			return color;
		} else if (color && color[0] === "$") {
			return variables.default[color.replace("$", "")];
		}
	},
});
</script>

<style lang="scss">
.tn-ui-tile {
	font-family: $font-family-telenor-ui;

	@include font-text-m;

	&.selectable {
		cursor: pointer;
	}

	&.xs {
		@include font-text-xs;
	}

	&.outline {
		.inner {
			border: 1px solid #d6d6d6;
		}

		&.selected {
			.inner {
				border: 1px solid #0078cf;
			}
		}
	}

	&.dashed {
		.inner {
			background-color: hsl(0deg 0% 100% / 50%) !important;
			border: 2px dashed #cecfd4;
		}
	}

	.right-of-content {
		flex-grow: 1;
	}

	.icon {
		overflow: hidden;
		margin-right: $spacing-s;
		display: flex;
		flex-direction: column;
		flex-shrink: 0;

		&.icon-top {
			align-self: flex-start;
		}

		img {
			max-width: 100%;
		}
	}

	.split-horizontally {
		display: flex;
		flex-direction: row;
		align-items: center;

		.left,
		.right {
			display: flex;
			flex-direction: column;
			flex-basis: 50%;
		}

		.right {
			align-items: flex-end;
		}
	}

	.padding-container {
		padding: $spacing-m;
		display: flex;
		flex-direction: column;
	}

	&.chevron {
		.padding-container {
			margin-right: $spacing-xl;
		}

		.inner {
			background-image: url("chevron.svg");
			background-repeat: no-repeat;
			background-size: 7.5px;
			background-position: right $spacing-l center;
		}
	}

	.inner {
		background-color: #e0eff9;
		border-radius: 7px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		text-decoration: none;
		color: $color-text;

		@include loading;

		&.nuxt-link-active {
			background-color: #f5f5f5;
		}

		&.nuxt-link-exact-active {
			background-color: #e0eff9;
		}

		.inner-above {
			display: flex;
			flex-direction: row;

			+ div {
				margin-top: $spacing-s;
			}
		}

		&.bg-dark {
			color: #fff;
		}

		&.disabled {
			opacity: 0.5;
			cursor: default;
		}

		&:not(.disabled, .noClick) {
			transition: background-color 0.2s;

			&:hover {
				background-color: #cae3f3;
			}
		}
	}

	.inner-container {
		display: flex;
		align-items: center;

		& ~ .inner-below {
			margin-top: 8px;
		}
	}

	&.vertical {
		.header {
			@include font-title-m;
		}

		.subheader {
			@include font-text-s;
		}

		.inner-container {
			flex-direction: column;
			align-items: flex-start;
			text-align: left;

			.icon {
				margin-right: 0;
				margin-bottom: $spacing-s;
			}
		}

		.padding-container,
		.inner {
			height: 100%;
		}

		.padding-container {
			justify-content: space-between;
		}
	}

	.header {
		font-weight: bold;

		+ .subheader {
			margin-top: $spacing-xs;
		}
	}

	.outer-below {
		margin: $spacing-m 0 $spacing-l 0;

		@include font-text-2xs;
	}

	h3 {
		@include font-title-bold-2xs;
	}

	.body-text {
		@include font-text-s;

		+ .button {
			align-self: flex-start;
			margin-top: $spacing-s;
		}
	}

	strong {
		font-weight: bold;
	}
}
</style>

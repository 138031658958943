<template>
	<div
		@click="$emit('input', !$props.modelValue)"
		class="circle-tick-checkbox"
		:class="[
			{
				active: $props.modelValue,
				blue: $props.blue,
			},
		]"
	>
		<svg
			width="14"
			height="10"
			viewBox="0 0 14 10"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			v-if="$props.modelValue"
		>
			<path
				d="M5.49956 9.57955C5.21356 9.57955 4.93856 9.46655 4.73606 9.26355L1.23606 5.76405C0.814056 5.34155 0.814056 4.65805 1.23606 4.23605C1.65806 3.81455 2.34106 3.81455 2.76306 4.23605L5.50006 6.97255L11.7366 0.736047C12.1581 0.314547 12.8426 0.314547 13.2641 0.736047C13.6861 1.15805 13.6861 1.84205 13.2641 2.26405L6.26406 9.26405C6.06106 9.46705 5.78556 9.57955 5.49956 9.57955Z"
				fill="white"
				stroke="white"
				stroke-width="0.5"
			/>
		</svg>
	</div>
</template>

<script>
export default defineNuxtComponent({
	name: "CircleTickCheckbox",

	props: {
		modelValue: { type: Boolean },
		blue: { type: Boolean },
	},
});
</script>

<style lang="scss">
.circle-tick-checkbox {
	width: 24px;
	height: 24px;
	border-radius: 50%;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border: 1px solid $color-cta-default;

	&.blue {
		border-color: $color-neutrals-200-tint;

		&.active {
			background: $color-cta-default;
			border-color: $color-cta-default;
		}
	}

	&.active {
		background: #38a841;
		border-color: #38a841;
	}
}
</style>
